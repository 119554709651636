const orcishQuotes = [
    "Lok'Tar Ogar - Victory or death!",
    "Zug Zug - Yes, yes.",
    "Dabu - Done.",
    "Throm'ka - Greetings!",
    "Kagh - Attack!",
    "Mak'Gora - Duel of honor.",
    "Grommash - War cry.",
    "Gol'kosh - By my axe!",
    "Karash - Destroy!",
    "Throm-ka - Well met!",
    "Kagh'nush - Move out!",
    "Nok'gar - Charge!",
    "Lom'tar - Defend!",
    "Thra'ka - Stand firm!",
    "Laz'gar - Victory!",
    "Kug'gor - Crush them!",
    "Mak'rak - Slay them all!",
    "Dabu'ka - As you wish.",
    "Lok'mok - Honor.",
    "Tor'gar - Strength.",
    "Nok'tor - Freedom.",
    "Grom'kar - For the Horde!",
    "Thok'nor - Glory!",
    "Kar'na - Fight!",
    "Nok'gor - Survive!",
    "Zug'gar - Attack!",
    "Lom'gar - Hold the line!",
    "Thrall'ka - Leader.",
    "Mok'ra - Be strong!",
    "Gar'thok - Battle ready!"
];

export const getRandomOrcishQuote = () => {
    const randomIndex = Math.floor(Math.random() * orcishQuotes.length);
    return orcishQuotes[randomIndex];
}